/**
 * External dependencies
 */
import React, { createElement } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

/**
 * Internal dependencies
 */
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

import './Button.scss';

export interface ButtonProps {
	className?: string;
	href?: string;
	target?: string;
	isPrimary?: boolean;
	isSecondary?: boolean;
	isTertiary?: boolean;
	isRounded?: boolean;
	isWhite?: boolean;
	isDestructive?: boolean;
	isLarge?: boolean;
	isSmall?: boolean;
	isLink?: boolean;
	isBusy?: boolean;
	disabled?: boolean;
	noOutline?: boolean;
	children: React.ReactNode;
}

function Button( {
	href,
	target,
	isPrimary = false,
	isSecondary = false,
	isTertiary = false,
	isDestructive = false,
	isRounded = false,
	isWhite = false,
	isLarge = false,
	isSmall = false,
	isLink = false,
	isBusy = false,
	noOutline = false,
	className,
	disabled = false,
	children,
	...additionalProps
}: ButtonProps & React.HTMLProps<HTMLButtonElement> ) {
	let component: any = href !== undefined && ! disabled ? 'a' : 'button';
	let tagProps;

	// Use <Link> for relative URLs.
	if ( component === 'a' && 0 === href.indexOf( '/' ) ) {
		component = Link;
		tagProps = { to: href };
	} else if ( component === 'a' ) {
		tagProps = {
			href,
			target,
		};
	} else {
		tagProps = {
			type: 'button',
			disabled,
		};
	}

	const classes = classnames( 'button', className, {
		'button--is-primary': isPrimary,
		'button--is-secondary': isSecondary,
		'button--is-tertiary': isTertiary,
		'button--is-rounded': isRounded,
		'button--is-white': isWhite,
		'button--is-large': isLarge,
		'button--is-small': isSmall,
		'button--is-busy': isBusy,
		'button--is-link': isLink,
		'button--is-destructive': isDestructive,
		'button--is-disabled': disabled,
		'button--no-outline': noOutline,
	} );

	return createElement(
		component,
		{
			...tagProps,
			...additionalProps,
			className: classes,
		},
		children,
		isBusy ? <LoadingIndicator /> : null,
	);
}

export default Button;
