/**
 * External dependencies
 */
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { ReactComponent as CrossIcon } from './x.svg';
import { ReactComponent as InfoIcon } from './info.svg';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import { Notice as NoticeInterface } from '../../store/types';

import './Notice.scss';

interface NoticeProps {
	className?: string;
	status?: NoticeInterface['status'];
	onRemove?(): void;
	isDismissible?: NoticeInterface['isDismissible'];
	children: React.ReactNode;
	showIcon?: boolean;
}

function Notice( {
	className,
	status = 'info',
	children,
	onRemove = () => {},
	isDismissible = true,
	showIcon,
}: NoticeProps ) {
	const classes = classnames(
		className,
		'notice', 'notice--is-' + status,
		{ 'notice--is-dismissible': isDismissible },
		{ 'notice--show-icon': showIcon },
	);

	return (
		<div className={ classes }>
			{ showIcon &&
				<InfoIcon className="notice__icon" role="img" aria-hidden="true" focusable="false" />
			}
			<div className="notice__content">
				{ children }
			</div>
			{ isDismissible && (
				<button
					className="notice__dismiss"
					onClick={ onRemove }
				>
					<VisuallyHidden>Ausblenden</VisuallyHidden>
					<CrossIcon className="notice__dismiss-icon" role="img" aria-hidden="true" focusable="false" />
				</button>
			) }
		</div>
	);
}

export default Notice;
