/**
 * External dependencies
 */
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './Nav.scss';

const Nav = ( {
	className,
	label,
	children,
} ) => {
	return (
		<nav className={ classnames( 'navigation', className ) } aria-label={ label }>
			{ children }
		</nav>
	);
};

export default Nav;
