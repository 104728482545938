/**
 * External dependencies
 */
import React from 'react';
import { Link } from 'react-router-dom';
/**
 * Internal dependencies
 */
import LoginModal from '../LoginModal/LoginModal';
import useMenu, { COLOPHON_MENU } from '../../hooks/useMenu';
import { isLocalUrl, getCurrentOrigin } from '../../utils';

import './Footer.scss';

const Footer = () => {
	const [ menuItems ] = useMenu( COLOPHON_MENU );

	return (
		<>
			<footer className="app-footer">
				<div className="app-footer__colophon">
					<div className="app-footer__colophon-inner">
						<ul className="colophon__list">
							<li className="colophon__list-item app-copyright">
								<span className="app-copyright__date">
									&copy; { ( new Date().getFullYear() ) }
								</span>
								<span className="app-copyright__organisation">
									berufswahl.zh.ch
								</span>
								<span className="app-copyright__sdbb">
									SDBB, Bern | gefunden auf berufswahl.zh.ch
								</span>
							</li>
							<li className="colophon__list-item colophon__list-item--is-light app-version">{ process.env.REACT_APP_VERSION }</li>
						</ul>

						{
							menuItems.length > 0 && (
								<ul className="colophon__list">
									{
										menuItems.map( ( item ) => {
											if ( ! isLocalUrl( item.url ) ) {
												return (
													<li className="colophon__list-item" key={ item.id }>
														<a href={ item.url }>{ item.title }</a>
													</li>
												);
											}

											return (
												<li className="colophon__list-item" key={ item.id }>
													<Link to={ item.url.replace( getCurrentOrigin(), '' ) }>{ item.title }</Link>
												</li>
											);
										} )
									}
								</ul>
							)
						}
					</div>
				</div>
			</footer>
			<LoginModal />
		</>
	);
};

export default Footer;
