/**
 * External dependencies
 */
import React from 'react';
import { useSelect } from '@wordpress/data';

/**
 * Internal dependencies
 */
import { Nav, NavLink } from '../Nav';
import { ChevronRight as ChevronRightIcon } from 'react-feather';
import { Profile } from '../../store/types';
import { STORE_KEY } from '../../store';

import './ProfileNavigation.scss';

const ProfileNavigation = ( { onClick } ) => {
	const { profile }: { profile: Profile } = useSelect( ( select ) => {
		const {
			isAuthenticated,
			getProfile,
		} = select( STORE_KEY );

		return { profile: isAuthenticated() ? getProfile() : null };
	}, [] );

	return (
		<Nav className="meta-navigation meta-navigation--profile" label="Profilnavigation">

			{
				! profile && (
					<ul className="meta-navigation__list--not-logged-in">
						<li>
							<NavLink onClick={ onClick } to="/anmelden">Anmelden</NavLink>
						</li>
						<li>
							<NavLink className="navigation__item--registration" onClick={ onClick } to="/registrierung">Registrieren</NavLink>
						</li>
					</ul>
				)
			}
			{
				!! profile && (
					<>
						<div className="meta-navigation__dropdown--button">
							{ profile?.avatar_url ?
								<img src={ profile.avatar_url } alt="Dein Profilbild" height="24" width="24" /> :
								<div className="meta-navigation__dropdown--placeholder"></div>
							}
							<span>{ profile?.name || 'Wird geladen ...' }</span>
							<ChevronRightIcon />
						</div>

						<ul className="meta-navigation__list--logged-in">
							<li>
								<NavLink onClick={ onClick } to="/profil">
									Profil
								</NavLink>
							</li>
							{ 'organization_editor' !== profile?.role &&
								<li>
									<NavLink onClick={ onClick } to="/profil/aktivitäten">
										Aktivitäten
									</NavLink>
								</li>
							}
							{ 'young_person' === profile?.role &&
								<li>
									<NavLink onClick={ onClick } to="/profil/cv">
										Lebenslauf
									</NavLink>
								</li>
							}
							<li>
								<NavLink onClick={ onClick } to="/abmelden">Abmelden</NavLink>
							</li>
						</ul>
					</>
				)
			}
		</Nav>
	);
};

export default ProfileNavigation;
