/**
 * External dependencies
 */
import React, { useEffect } from 'react';

/**
 * Internal dependencies
 */
import { Nav, NavItem } from '../Nav';
import useMenu, { SECONDARY_MENU } from '../../hooks/useMenu';
import ContentPlaceholder from '../ContentPlaceholder/ContentPlaceholder';

const MetaNavigation = ( {
	onClick, onItemsLoaded,
} ) => {
	const [ menuItems, menuItemsIsLoading ] = useMenu( SECONDARY_MENU );

	// Execute parent callback. (Updates the list of focusable elements for focus trapping.)
	useEffect( () => {
		onItemsLoaded();
	}, [ onItemsLoaded, menuItems ] );

	return (
		<Nav className="meta-navigation meta-navigation--meta" label="Sekundärnavigation">
			<ul>
				{
					! menuItemsIsLoading && menuItems.length > 0 && (
						<>
							{
								menuItems.map( ( item ) => {
									return (
										<NavItem
											key={ item.id }
											item={ item }
											onClick={ onClick }
											isTopLevel={ true } />
									);
								} )
							}
						</>
					)
				}
				{
					menuItemsIsLoading && (
						<>
							<li>
								<ContentPlaceholder className="navigation__item" width={ 65 } />
							</li>
							<li>
								<ContentPlaceholder className="navigation__item" width={ 115 } />
							</li>
							<li>
								<ContentPlaceholder className="navigation__item" width={ 70 } />
							</li>
						</>
					)
				}
			</ul>
		</Nav>
	);
};

export default MetaNavigation;
