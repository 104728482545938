import { useSelect } from '@wordpress/data';

import { STORE_KEY } from '../store';
import type { AppStateMenu } from '../store/types';

interface MenuState {
	menuItems: AppStateMenu[];
	isMenuItemsFetching: boolean;
}

const EMPTY_ARRAY: AppStateMenu[] = [];

export const PRIMARY_MENU = 'primary';
export const SECONDARY_MENU = 'secondary';
export const COLOPHON_MENU = 'colophon';

export default function useMenu( slug: string ) {
	const {
		menuItems,
		isMenuItemsFetching,
	}: MenuState = useSelect( ( select ) => {
		const {
			getMenus,
			isMenusFetching,
		} = select( STORE_KEY );

		const menus = getMenus();

		return {
			menuItems: menus[ slug ] || EMPTY_ARRAY,
			isMenuItemsFetching: isMenusFetching(),
		};
	}, [ slug ] );

	return [ menuItems, isMenuItemsFetching ] as const;
}
