/**
 * External dependencies
 */
import React, { useContext } from 'react';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from 'react-feather';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { HeaderContext } from '../Header/HeaderContext';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import './SubNavigationButton.scss';

const SubNavigationButton = ( {
	children,
	controls,
	back = false,
} ) => {
	const {
		currentOpenSubMenus,
		openSubMenu,
		closeSubMenu,
	} = useContext( HeaderContext );

	const isOpen = currentOpenSubMenus.includes( controls );

	return (
		<button
			type="button"
			className={ classnames(
				'sub-menu-button',
				{ 'sub-menu-button--open': ! back },
				{ 'sub-menu-button--back': back },
			) }
			onClick={ () => {
				if ( ! back ) {
					if ( isOpen ) {
						closeSubMenu( controls );
					} else {
						openSubMenu( controls );
					}
				} else {
					closeSubMenu( controls );
				}
			} }
			aria-controls={ controls }
			aria-expanded={ isOpen }>
			{ ! back && (
				<>
					<VisuallyHidden className="sub-menu-button__hidden-text-open">Sub-Menü «{ children }» öffnen</VisuallyHidden>
					<VisuallyHidden className="sub-menu-button__hidden-text-close">Sub-Menü «{ children }» schließen</VisuallyHidden>
					<span className="sub-menu-button__text" aria-hidden="true">{ children }</span>
					<ChevronRightIcon />
				</>
			) }
			{ back && (
				<>
					<VisuallyHidden>Sub-Menü «{ children }» schliessen</VisuallyHidden>
					<span aria-hidden="true">
						<ChevronLeftIcon />
						<span className="sub-menu-button__text">{ children }</span>
					</span>
				</>
			) }
		</button>
	);
};

export default SubNavigationButton;
