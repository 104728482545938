const PROTOCOL_REGEXP = /^([^\s:]+:)/i;

export const getCurrentOrigin = (): string => {
	return window.location.origin ? window.location.origin : window.location.protocol + '//' + window.location.host;
};

export const isLocalUrl = ( url: string ): boolean => {
	// Check if URL starts with a protocol, if not, assume it's local.
	if ( ! PROTOCOL_REGEXP.test( url ) ) {
		return true;
	}

	// Absolute URL on same origin.
	const origin = getCurrentOrigin();
	if ( 0 === url.indexOf( origin ) ) {
		return true;
	}

	return false;
};
