/**
 * External dependencies
 */
import React, { useRef } from 'react';
import { ErrorBoundary as BaseSentryErrorBoundary } from '@sentry/react';
import { useSelect } from '@wordpress/data';

/**
 * Internal dependencies
 */
import { Profile } from '../../store/types';
import Button from '../Button/Button';
import { STORE_KEY } from '../../store';

import '../ErrorBoundary/ErrorBoundary.scss';

interface SentryErrorBoundaryProps {
	children?: ( ( props: any ) => React.ReactNode ) | React.ReactNode;
	component: string;
}

const Fallback = ( { error } ) => {
	const redirectToDashboard = () => window.location.href = window.location.origin;
	const reload = () => window.location.reload();
	const refTextarea = useRef( null );
	const copyError = () => {
		refTextarea.current.select();
		try {
			document.execCommand( 'copy' );
		} catch ( e ) {}
	};

	/* eslint-disable jsx-a11y/accessible-emoji */
	return (
		<div className="error-boundary">
			<div className="error-boundary__content">
				<h1>Ein unerwarteter Fehler ist aufgetreten. 😭</h1>
				<p>Bitte lade die Seite neu oder gehe zurück zu Startseite.</p>

				<p>
					<Button isPrimary onClick={ reload }>Seite neuladen</Button>
					<Button onClick={ redirectToDashboard }>Zur Startseite wechseln</Button>
				</p>
				<p>Sollte der Fehler bestehen bleiben, <Button isLink onClick={ copyError }>kopiere hier die Fehlerdetails in die Zwischenablage</Button> und sende diese an <a href="mailto:feedback@berufswahl.zh.ch">feedback@berufswahl.zh.ch.</a></p>
				<textarea ref={ refTextarea } className="error-boundary__stack" defaultValue={ error.stack } />
			</div>
		</div>
	);
};

const SentryErrorBoundary = ( {
	children,
	component,
}: SentryErrorBoundaryProps ) => {
	const profile: Profile = useSelect( ( select ) => {
		const {
			isAuthenticated,
			getProfile,
		} = select( STORE_KEY );

		return isAuthenticated() ? getProfile() : null;
	}, [] );

	return (
		<BaseSentryErrorBoundary beforeCapture={ ( scope ) => {
			scope.setTag( 'component', component );
			if ( profile?.email ) {
				scope.setUser( { email: profile.email } );
			}
		} } fallback={ ( props ) => <Fallback { ...props } /> }>
			{ children }
		</BaseSentryErrorBoundary>
	);
};

export default SentryErrorBoundary;
