/**
 * External dependencies
 */
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { NavLink, SubNavigation } from '.';

const NavItem = ( {
	className = '',
	item,
	onClick = null,
	isActiveCallback = null,
	isTopLevel = false,
} ) => {
	return (
		<li { ...( ( item.children.length > 0 || className ) && {
			className: classnames(
				className,
				{ 'sub-navigation__item--simple': item.children.length === 0 },
				{ 'sub-navigation__item--has-sub': item.children.length > 0 },
			),
		} ) }>
			{
				item.children.length > 0 && (
					<SubNavigation
						item={ item }
						onClick={ onClick }
						isTopLevel={ isTopLevel }
					/>
				)
			}
			{
				item.children.length === 0 && (
					<NavLink
						onClick={ onClick }
						to={ item.url }
						isActive={ isActiveCallback }>{ item.title }</NavLink>
				)
			}
		</li>
	);
};

export default NavItem;
