/**
 * External dependencies
 */
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './ContentPlaceholder.scss';

const randomInt = ( min: number, max: number ): number => Math.random() * ( max - min ) + min;

interface ContentPlaceholderProps {
	className?: string;
	as?: React.ElementType<any>;
	height?: number;
	width?: number;
	lines?: number;
}

const ContentPlaceholder = ( {
	as: Component = 'span',
	height,
	width,
	lines = 1,
	className,
	...rest
}: ContentPlaceholderProps & React.HTMLProps<any> ) => {
	const delay = randomInt( 150, 500 );
	const textPlaceholder = '\u00a0<br/>'.repeat( lines );

	return (
		<Component
			{ ...rest }
			style={ {
				height: height ? `${ height }px` : null,
				width: width ? `${ width }px` : null,
				animationDelay: `${ delay }ms`,
			} }
			className={ classnames( 'content-placeholder', className ) }
			aria-hidden={ true }
			dangerouslySetInnerHTML={ { __html: textPlaceholder } }
		/>
	);
};

export default ContentPlaceholder;
