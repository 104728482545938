/**
 * External dependencies
 */
import { first, isEmpty } from 'lodash';
import { addQueryArgs } from '@wordpress/url';

/**
 * Internal dependencies
 */
import { apiFetch } from './controls';

import {
	fetchPage,
	fetchPageSuccess,
	fetchPageFailure,
	receivePage,
	fetchProfile,
	fetchProfileSuccess,
	fetchProfileFailure,
	receiveProfile,
	fetchSchools,
	fetchSchoolsSuccess,
	fetchSchoolsFailure,
	receiveSchools,
	fetchTeachers,
	fetchTeachersSuccess,
	fetchTeachersFailure,
	receiveTeachers,
	fetchDistricts,
	fetchDistrictsSuccess,
	fetchDistrictsFailure,
	receiveDistricts,
	fetchCities,
	fetchCitiesSuccess,
	fetchCitiesFailure,
	receiveCities,
	fetchEventAudience,
	fetchEventAudienceSuccess,
	fetchEventAudienceFailure,
	receiveEventAudience,
	fetchEventTopics,
	fetchEventTopicsSuccess,
	fetchEventTopicsFailure,
	receiveEventTopics,
	fetchOffices,
	fetchOfficesSuccess,
	fetchOfficesFailure,
	receiveOffices,
	fetchProfessionFields,
	fetchProfessionFieldsSuccess,
	fetchProfessionFieldsFailure,
	receiveProfessionFields,
	fetchProfessionInterests,
	fetchProfessionInterestsSuccess,
	fetchProfessionInterestsFailure,
	receiveProfessionInterests,
	fetchProfessionSectors,
	fetchProfessionSectorsSuccess,
	fetchProfessionSectorsFailure,
	receiveProfessionSectors,
	fetchProfessionDurations,
	fetchProfessionDurationsSuccess,
	fetchProfessionDurationsFailure,
	receiveProfessionDurations,
	fetchProfessionQualifications,
	fetchProfessionQualificationsSuccess,
	fetchProfessionQualificationsFailure,
	receiveProfessionQualifications,
	fetchProfessionBilingualEducation,
	fetchProfessionBilingualEducationSuccess,
	fetchProfessionBilingualEducationFailure,
	receiveProfessionBilingualEducation,
	fetchProfessions,
	fetchProfessionsSuccess,
	fetchProfessionsFailure,
	receiveProfessions,
	fetchApprenticeshipProfessions,
	fetchApprenticeshipProfessionsSuccess,
	fetchApprenticeshipProfessionsFailure,
	receiveApprenticeshipProfessions,
	fetchApprenticeshipLocations,
	fetchApprenticeshipLocationsSuccess,
	fetchApprenticeshipLocationsFailure,
	receiveApprenticeshipLocations,
	fetchApprenticeships,
	fetchApprenticeshipsSuccess,
	fetchApprenticeshipsFailure,
	receiveApprenticeships,
	fetchTrialApprenticeships,
	fetchTrialApprenticeshipsSuccess,
	fetchTrialApprenticeshipsFailure,
	receiveTrialApprenticeships,
	fetchEvents,
	fetchEventsSuccess,
	fetchEventsFailure,
	receiveEvents,
	fetchSearchResults,
	fetchSearchResultsSuccess,
	fetchSearchResultsFailure,
	receiveSearchResults,
	createErrorNotice,
	removeAuthToken,
	removeProfile,
	fetchUserConnections,
	fetchUserConnectionsSuccess,
	fetchUserConnectionsFailure,
	receiveUserConnections,
	fetchUser,
	fetchUserSuccess,
	fetchUserFailure,
	receiveUser,
	fetchCurrentUserRememberedItems,
	fetchCurrentUserRememberedItemsSuccess,
	fetchCurrentUserRememberedItemsFailure,
	receiveCurrentUserRememberedItems,
	receiveLanguages,
	fetchLanguages,
	fetchLanguagesSuccess,
	fetchLanguagesFailure,
	receiveNationalities,
	fetchNationalities,
	fetchNationalitiesSuccess,
	fetchNationalitiesFailure,
	fetchCurrentUserActivities,
	receiveCurrentUserActivities,
	fetchCurrentUserActivitiesSuccess,
	fetchCurrentUserActivitiesFailure,
	fetchUserActivities,
	receiveUserActivities,
	fetchUserActivitiesSuccess,
	fetchUserActivitiesFailure,
	fetchUserRememberedItems,
	receiveUserRememberedItems,
	fetchUserRememberedItemsSuccess,
	fetchUserRememberedItemsFailure,
	receiveNews,
	fetchNews,
	fetchNewsSuccess,
	fetchNewsFailure,
	fetchOrganizations,
	fetchOrganizationsSuccess,
	fetchOrganizationsFailure,
	receiveOrganizations,
	receiveMenus,
	fetchMenus,
	fetchMenusSuccess,
	fetchMenusFailure,
	fetchPosts,
	fetchPostsSuccess,
	fetchPostsFailure,
	receivePosts,
} from './actions';

import { AppState } from './types';

/**
 * Requests a page by path from the REST API.
 */
export const getPage = {
	*fulfill( pagePath, pagePassword ) {
		yield fetchPage( pagePath, pagePassword );

		const path = addQueryArgs( '/wp-json/biz/v1/pages', {
			path: pagePath,
			password: pagePassword || undefined,
		} );

		try {
			const {
				items,
				total,
			} = yield apiFetch( { path } );

			const notFound = {
				code: 'not_found',
				message: 'Page not found.',
			};

			if ( 1 !== total ) {
				throw notFound;
			}

			const page: any = first( items );

			if ( page.errors?.rest_post_incorrect_password ) {
				const invalidPassword = {
					code: 'invalid_password',
					message: 'Invalid password.',
				};

				throw invalidPassword;
			}

			yield receivePage(
				pagePath,
				pagePassword,
				page,
			);

			yield fetchPageSuccess(
				pagePath,
				pagePassword,
			);
		} catch ( error ) {
			yield fetchPageFailure(
				pagePath,
				pagePassword,
				error,
			);
		}
	},
	isFulfilled( state, pagePath, pagePassword ) {
		const pageKey = `${ pagePath }${ pagePassword }`;
		return 'undefined' !== typeof state.pages.results[ pageKey ];
	},
};

/**
 * Requests the profile of the current user from the REST API.
 */
export const getProfile = {
	*fulfill() {
		yield fetchProfile();

		try {
			const profile = yield apiFetch( { path: '/wp-json/biz/v1/users/me' } );

			yield receiveProfile( profile );
			yield fetchProfileSuccess();
		} catch ( error ) {
			yield removeProfile();

			if ( 'request_error' !== error.code ) {
				yield removeAuthToken();
			}

			yield fetchProfileFailure( error );

			yield createErrorNotice( 'Profil konnte nicht geladen werden', {
				type: 'snackbar',
				action: {
					label: 'Neuladen',
					onClick: () => window.location.reload(),
				},
			} );
		}
	},
	isFulfilled( state ) {
		return ! isEmpty( state.profile.data );
	},
};

/**
 * Requests schools from the REST API.
 */
export function* getSchools() {
	yield fetchSchools();

	try {
		const schools = yield apiFetch( { path: '/wp-json/biz/v1/schools' } );

		yield receiveSchools( schools );
		yield fetchSchoolsSuccess();
	} catch ( error ) {
		yield fetchSchoolsFailure( error );
	}
}

/**
 * Requests teachers from the REST API.
 */
export function* getTeachers( schoolID ) {
	yield fetchTeachers( schoolID );

	const path = addQueryArgs( '/wp-json/biz/v1/teachers', { school_id: schoolID } );

	try {
		const teachers = yield apiFetch( { path } );

		yield receiveTeachers(
			teachers,
			schoolID,
		);
		yield fetchTeachersSuccess( schoolID );
	} catch ( error ) {
		yield fetchTeachersFailure(
			error,
			schoolID,
		);
	}
}

/**
 * Requests districts from the REST API.
 */
export function* getDistricts( query = {} ) {
	yield fetchDistricts( query );

	const path = addQueryArgs( '/wp-json/biz/v1/districts', query );

	try {
		const items = yield apiFetch( { path } );

		yield receiveDistricts( query, items );
		yield fetchDistrictsSuccess( query );
	} catch ( error ) {
		yield fetchDistrictsFailure( query, error );
	}
}

/**
 * Requests cities from the REST API.
 */
export function* getCities() {
	yield fetchCities();

	try {
		const cities = yield apiFetch( { path: '/wp-json/biz/v1/cities' } );

		yield receiveCities( cities );
		yield fetchCitiesSuccess();
	} catch ( error ) {
		yield fetchCitiesFailure( error );
	}
}

/**
 * Requests event audience from the REST API.
 */
export function* getEventAudience() {
	yield fetchEventAudience();

	try {
		const audience = yield apiFetch( { path: '/wp-json/biz/v1/event-audience' } );

		yield receiveEventAudience( audience );
		yield fetchEventAudienceSuccess();
	} catch ( error ) {
		yield fetchEventAudienceFailure( error );
	}
}

/**
 * Requests event topics from the REST API.
 */
export function* getEventTopics() {
	yield fetchEventTopics();

	try {
		const topics = yield apiFetch( { path: '/wp-json/biz/v1/event-topics' } );

		yield receiveEventTopics( topics );
		yield fetchEventTopicsSuccess();
	} catch ( error ) {
		yield fetchEventTopicsFailure( error );
	}
}

/**
 * Requests offices from the REST API.
 */
export function* getOffices( query = {} ) {
	yield fetchOffices( query );

	const path = addQueryArgs( '/wp-json/biz/v1/offices', query );

	try {
		const {
			items,
			count,
			total,
			totalPages,
		} = yield apiFetch( { path } );

		yield receiveOffices(
			query,
			items,
			count,
			total,
			totalPages,
		);

		yield fetchOfficesSuccess( query );
	} catch ( error ) {
		yield fetchOfficesFailure( query, error );
	}
}

/**
 * Requests profession fields from the REST API.
 */
export function* getProfessionFields() {
	yield fetchProfessionFields();

	try {
		const fields = yield apiFetch( { path: '/wp-json/biz/v1/profession-fields' } );

		yield receiveProfessionFields( fields );
		yield fetchProfessionFieldsSuccess();
	} catch ( error ) {
		yield fetchProfessionFieldsFailure( error );
	}
}

/**
 * Requests profession interests from the REST API.
 */
export function* getProfessionInterests() {
	yield fetchProfessionInterests();

	try {
		const interests = yield apiFetch( { path: '/wp-json/biz/v1/profession-interests' } );

		yield receiveProfessionInterests( interests );
		yield fetchProfessionInterestsSuccess();
	} catch ( error ) {
		yield fetchProfessionInterestsFailure( error );
	}
}

/**
 * Requests profession sectors from the REST API.
 */
export function* getProfessionSectors() {
	yield fetchProfessionSectors();

	try {
		const sectors = yield apiFetch( { path: '/wp-json/biz/v1/profession-sectors' } );

		yield receiveProfessionSectors( sectors );
		yield fetchProfessionSectorsSuccess();
	} catch ( error ) {
		yield fetchProfessionSectorsFailure( error );
	}
}

/**
 * Requests profession durations from the REST API.
 */
export function* getProfessionDurations() {
	yield fetchProfessionDurations();

	try {
		const durations = yield apiFetch( { path: '/wp-json/biz/v1/profession-durations' } );

		yield receiveProfessionDurations( durations );
		yield fetchProfessionDurationsSuccess();
	} catch ( error ) {
		yield fetchProfessionDurationsFailure( error );
	}
}

/**
 * Requests profession qualifications from the REST API.
 */
export function* getProfessionQualifications() {
	yield fetchProfessionQualifications();

	try {
		const qualifications = yield apiFetch( { path: '/wp-json/biz/v1/profession-qualifications' } );

		yield receiveProfessionQualifications( qualifications );
		yield fetchProfessionQualificationsSuccess();
	} catch ( error ) {
		yield fetchProfessionQualificationsFailure( error );
	}
}

/**
 * Requests profession bilingual education from the REST API.
 */
export function* getProfessionBilingualEducation() {
	yield fetchProfessionBilingualEducation();

	try {
		const bilingualEducation = yield apiFetch( { path: '/wp-json/biz/v1/profession-bilingual-education' } );

		yield receiveProfessionBilingualEducation( bilingualEducation );
		yield fetchProfessionBilingualEducationSuccess();
	} catch ( error ) {
		yield fetchProfessionBilingualEducationFailure( error );
	}
}

/**
 * Requests professions from the REST API.
 */
export function* getProfessions( query = {} ) {
	yield fetchProfessions( query );

	const path = addQueryArgs( '/wp-json/biz/v1/professions', query );

	try {
		const {
			items,
			count,
			total,
			totalPages,
		} = yield apiFetch( { path } );

		yield receiveProfessions(
			query,
			items,
			count,
			total,
			totalPages,
		);

		yield fetchProfessionsSuccess( query );
	} catch ( error ) {
		yield fetchProfessionsFailure( query, error );
	}
}

/**
 * Requests professions from the REST API.
 */
export function* getSearchResults( query = {} ) {
	yield fetchSearchResults( query );

	const path = addQueryArgs( '/wp-json/biz/v1/search', query );

	try {
		const {
			items,
			count,
			total,
			totalPages,
		} = yield apiFetch( { path } );

		yield receiveSearchResults(
			query,
			items,
			count,
			total,
			totalPages,
		);

		yield fetchSearchResultsSuccess( query );
	} catch ( error ) {
		yield fetchSearchResultsFailure( query, error );
	}
}

/**
 * Requests apprenticeship professions from the REST API.
 */
export function* getApprenticeshipProfessions( query = {} ) {
	yield fetchApprenticeshipProfessions( query );

	const path = addQueryArgs( '/wp-json/biz/v1/apprenticeship-professions', query );

	try {
		const items = yield apiFetch( { path } );

		yield receiveApprenticeshipProfessions( query, items );
		yield fetchApprenticeshipProfessionsSuccess( query );
	} catch ( error ) {
		yield fetchApprenticeshipProfessionsFailure( query, error );
	}
}

/**
 * Requests an apprenticeship profession by slug from the REST API.
 */
export const getApprenticeshipProfession = {
	*fulfill( slug: string ) {
		const query = { slug };
		yield fetchApprenticeshipProfessions( query );

		const path = addQueryArgs( '/wp-json/biz/v1/apprenticeship-professions', query );

		try {
			const items = yield apiFetch( { path } );

			yield receiveApprenticeshipProfessions( query, items );
			yield fetchApprenticeshipProfessionsSuccess( query );
		} catch ( error ) {
			yield fetchApprenticeshipProfessionsFailure( query, error );
		}
	},
	isFulfilled( state: AppState, slug: string ) {
		return ! isEmpty( state.apprenticeshipProfessions.items[ slug ] );
	},
};

/**
 * Requests apprenticeship locations from the REST API.
 */
export function* getApprenticeshipLocations( query = {} ) {
	yield fetchApprenticeshipLocations( query );

	const path = addQueryArgs( '/wp-json/biz/v1/apprenticeship-locations', query );

	try {
		const items = yield apiFetch( { path } );

		yield receiveApprenticeshipLocations( query, items );

		yield fetchApprenticeshipLocationsSuccess( query );
	} catch ( error ) {
		yield fetchApprenticeshipLocationsFailure( query, error );
	}
}

/**
 * Requests apprenticeship from the REST API.
 */
export function* getApprenticeships( query = {} ) {
	yield fetchApprenticeships( query );

	const path = addQueryArgs( '/wp-json/biz/v3/apprenticeships', query );

	try {
		const { items } = yield apiFetch( { path } );

		yield receiveApprenticeships( query, items );
		yield fetchApprenticeshipsSuccess( query );
	} catch ( error ) {
		yield fetchApprenticeshipsFailure( query, error );
	}
}

/**
 * Requests an apprenticeship by slug from the REST API.
 */
export const getApprenticeship = {
	*fulfill( slug: string ) {
		const query = { slug };
		yield fetchApprenticeships( query );

		const path = addQueryArgs( '/wp-json/biz/v3/apprenticeships', query );

		try {
			const { items } = yield apiFetch( { path } );

			yield receiveApprenticeships( query, items );
			yield fetchApprenticeshipsSuccess( query );
		} catch ( error ) {
			yield fetchApprenticeshipsFailure( query, error );
		}
	},
	isFulfilled( state: AppState, slug: string ) {
		return ! isEmpty( state.apprenticeships.items[ slug ] );
	},
};

/**
 * Requests trial apprenticeship from the REST API.
 */
export function* getTrialApprenticeships( query = {} ) {
	yield fetchTrialApprenticeships( query );

	const path = addQueryArgs( '/wp-json/biz/v1/trial-apprenticeships', query );

	try {
		const { items } = yield apiFetch( { path } );

		yield receiveTrialApprenticeships( query, items );
		yield fetchTrialApprenticeshipsSuccess( query );
	} catch ( error ) {
		yield fetchTrialApprenticeshipsFailure( query, error );
	}
}

/**
 * Requests a trial apprenticeship by slug from the REST API.
 */
export const getTrialApprenticeship = {
	*fulfill( slug: string ) {
		const query = { slug };
		yield fetchTrialApprenticeships( query );

		const path = addQueryArgs( '/wp-json/biz/v1/trial-apprenticeships', query );

		try {
			const { items } = yield apiFetch( { path } );

			yield receiveTrialApprenticeships( query, items );
			yield fetchTrialApprenticeshipsSuccess( query );
		} catch ( error ) {
			yield fetchTrialApprenticeshipsFailure( query, error );
		}
	},
	isFulfilled( state: AppState, slug: string ) {
		return ! isEmpty( state.trialApprenticeships.items[ slug ] );
	},
};

/**
 * Requests a profession by slug from the REST API.
 */
export const getProfession = {
	*fulfill( slug: string ) {
		const query = { slug };
		yield fetchProfessions( query );

		const path = addQueryArgs( '/wp-json/biz/v1/professions', query );

		try {
			const {
				items,
				count,
				total,
				totalPages,
			} = yield apiFetch( { path } );

			yield receiveProfessions(
				query,
				items,
				count,
				total,
				totalPages,
			);

			yield fetchProfessionsSuccess( query );
		} catch ( error ) {
			yield fetchProfessionsFailure( query, error );
		}
	},
	isFulfilled( state: AppState, slug: string ) {
		return ! isEmpty( state.professions.items[ slug ] );
	},
};

/**
 * Requests events from the REST API.
 */
export function* getEvents( query = {} ) {
	yield fetchEvents( query );

	const path = addQueryArgs( '/wp-json/biz/v1/events', query );

	try {
		const {
			items,
			count,
			total,
			totalPages,
		} = yield apiFetch( { path } );

		yield receiveEvents(
			query,
			items,
			count,
			total,
			totalPages,
		);

		yield fetchEventsSuccess( query );
	} catch ( error ) {
		yield fetchEventsFailure( query, error );
	}
}

/**
 * Requests an event by slug from the REST API.
 */
export const getEvent = {
	*fulfill( slug: string ) {
		const query = { slug };
		yield fetchEvents( query );

		const path = addQueryArgs( '/wp-json/biz/v1/events', query );

		try {
			const {
				items,
				count,
				total,
				totalPages,
			} = yield apiFetch( { path } );

			yield receiveEvents(
				query,
				items,
				count,
				total,
				totalPages,
			);

			yield fetchEventsSuccess( query );
		} catch ( error ) {
			yield fetchEventsFailure(
				query,
				error,
			);
		}
	},
	isFulfilled( state: AppState, slug: string ) {
		return ! isEmpty( state.events.items[ slug ] );
	},
};

/**
 * Requests user connections from the REST API.
 */
export const getUserConnections = {
	*fulfill() {
		yield fetchUserConnections();

		try {
			const connections = yield apiFetch( { path: '/wp-json/biz/v1/user-connections' } );

			yield receiveUserConnections( connections );
			yield fetchUserConnectionsSuccess();
		} catch ( error ) {
			yield fetchUserConnectionsFailure( error );
		}
	},
	shouldInvalidate( action ) {
		return 'INVALIDATE_USER_CONNECTIONS' === action.type;
	},
};

/**
 * Requests current user remembered items from the REST API.
 */
export const getCurrentUserRememberedItems = {
	*fulfill() {
		yield fetchCurrentUserRememberedItems();

		try {
			const items = yield apiFetch( { path: '/wp-json/biz/v1/users/me/remembered' } );

			yield receiveCurrentUserRememberedItems( items );
			yield fetchCurrentUserRememberedItemsSuccess();
		} catch ( error ) {
			yield fetchCurrentUserRememberedItemsFailure( error );
		}
	},
	shouldInvalidate( action ) {
		return 'INVALIDATE_CURRENT_USER_REMEMBERED_ITEMS' === action.type;
	},
};

export const getCurrentUserRememberedApprenticeshipItems = {
	*fulfill() {
		yield getCurrentUserRememberedItems.fulfill();
	},
	isFulfilled( state: AppState ) {
		return state.currentUserRememberedItems.isFetching || null !== state.currentUserRememberedItems.results;
	},
	shouldInvalidate( action ) {
		return 'INVALIDATE_CURRENT_USER_REMEMBERED_ITEMS' === action.type;
	},
};

export const getCurrentUserRememberedEventItems = {
	*fulfill() {
		yield getCurrentUserRememberedItems.fulfill();
	},
	isFulfilled( state: AppState ) {
		return state.currentUserRememberedItems.isFetching || null !== state.currentUserRememberedItems.results;
	},
	shouldInvalidate( action ) {
		return 'INVALIDATE_CURRENT_USER_REMEMBERED_ITEMS' === action.type;
	},
};

export const getCurrentUserRememberedProfessionItems = {
	*fulfill() {
		yield getCurrentUserRememberedItems.fulfill();
	},
	isFulfilled( state: AppState ) {
		return state.currentUserRememberedItems.isFetching || null !== state.currentUserRememberedItems.results;
	},
	shouldInvalidate( action ) {
		return 'INVALIDATE_CURRENT_USER_REMEMBERED_ITEMS' === action.type;
	},
};

/**
 * Requests current user remembered items from the REST API.
 */
export const getUserRememberedItems = {
	*fulfill( id: number ) {
		yield fetchUserRememberedItems( id );

		try {
			const items = yield apiFetch( { path: `/wp-json/biz/v1/users/${ id }/remembered` } );

			yield receiveUserRememberedItems( id, items );
			yield fetchUserRememberedItemsSuccess( id );
		} catch ( error ) {
			yield fetchUserRememberedItemsFailure( id, error );
		}
	},
	shouldInvalidate( action ) {
		return 'INVALIDATE_USER_REMEMBERED_ITEMS' === action.type;
	},
};

export const getUserRememberedApprenticeshipItems = {
	*fulfill( id: number ) {
		yield getUserRememberedItems.fulfill( id );
	},
	isFulfilled( state: AppState, id: number ) {
		return state.userRememberedItems.isFetching[ id ] || ! isEmpty( state.userRememberedItems.results[ id ] );
	},
	shouldInvalidate( action ) {
		return 'INVALIDATE_USER_REMEMBERED_ITEMS' === action.type;
	},
};

export const getUserRememberedEventItems = {
	*fulfill( id: number ) {
		yield getUserRememberedItems.fulfill( id );
	},
	isFulfilled( state: AppState, id: number ) {
		return state.userRememberedItems.isFetching[ id ] || ! isEmpty( state.userRememberedItems.results[ id ] );
	},
	shouldInvalidate( action ) {
		return 'INVALIDATE_USER_REMEMBERED_ITEMS' === action.type;
	},
};

export const getUserRememberedProfessionItems = {
	*fulfill( id: number ) {
		yield getUserRememberedItems.fulfill( id );
	},
	isFulfilled( state: AppState, id: number ) {
		return state.userRememberedItems.isFetching[ id ] || ! isEmpty( state.userRememberedItems.results[ id ] );
	},
	shouldInvalidate( action ) {
		return 'INVALIDATE_USER_REMEMBERED_ITEMS' === action.type;
	},
};

/**
 * Requests user data from the REST API.
 */
export const getUser = {
	*fulfill( id: number ) {
		yield fetchUser( id );

		try {
			const user = yield apiFetch( { path: `/wp-json/biz/v1/users/${ id }` } );

			yield receiveUser( user );
			yield fetchUserSuccess( id );
		} catch ( error ) {
			yield fetchUserFailure( id, error );
		}
	},
	shouldInvalidate( action ) {
		return 'INVALIDATE_USERS' === action.type;
	},
};

/**
 * Requests languages from the REST API.
 */
export function* getLanguages() {
	yield fetchLanguages();

	try {
		const languages = yield apiFetch( { path: '/wp-json/biz/v1/user-languages' } );

		yield receiveLanguages( languages );
		yield fetchLanguagesSuccess();
	} catch ( error ) {
		yield fetchLanguagesFailure( error );
	}
}

/**
 * Requests nationalities from the REST API.
 */
export function* getNationalities() {
	yield fetchNationalities();

	try {
		const nationalities = yield apiFetch( { path: '/wp-json/biz/v1/user-nationalities' } );

		yield receiveNationalities( nationalities );
		yield fetchNationalitiesSuccess();
	} catch ( error ) {
		yield fetchNationalitiesFailure( error );
	}
}

/**
 * Requests current user activities from the REST API.
 */
export const getUserActivities = {
	*fulfill( id: number ) {
		yield fetchUserActivities( id );

		try {
			const activities = yield apiFetch( { path: `/wp-json/biz/v1/users/${ id }/activities` } );

			yield receiveUserActivities( id, activities );

			yield fetchUserActivitiesSuccess( id );
		} catch ( error ) {
			yield fetchUserActivitiesFailure( id, error );
		}
	},
	shouldInvalidate( action ) {
		return 'INVALIDATE_USER_ACTIVITIES' === action.type;
	},
};

/**
 * Requests current user activities from the REST API.
 */
export const getCurrentUserActivities = {
	*fulfill() {
		yield fetchCurrentUserActivities();

		try {
			const activities = yield apiFetch( { path: '/wp-json/biz/v1/users/me/activities' } );

			yield receiveCurrentUserActivities( activities );
			yield fetchCurrentUserActivitiesSuccess();
		} catch ( error ) {
			yield fetchCurrentUserActivitiesFailure( error );
		}
	},
	shouldInvalidate( action ) {
		return 'INVALIDATE_CURRENT_USER_ACTIVITIES' === action.type;
	},
};

/**
 * Requests news from the REST API.
 */
export function* getNews() {
	yield fetchNews();

	try {
		const items = yield apiFetch( { path: '/wp-json/biz/v1/widgets/news' } );

		yield receiveNews( items );
		yield fetchNewsSuccess();
	} catch ( error ) {
		yield fetchNewsFailure( error );
	}
}

/**
 * Requests organizations from the REST API.
 */
export function* getOrganizations( query = {} ) {
	yield fetchOrganizations( query );

	const path = addQueryArgs( '/wp-json/biz/v1/organizations', query );

	try {
		const {
			items,
			count,
			total,
			totalPages,
		} = yield apiFetch( { path } );

		yield receiveOrganizations(
			query,
			items,
			count,
			total,
			totalPages,
		);

		yield fetchOrganizationsSuccess( query );
	} catch ( error ) {
		yield fetchOrganizationsFailure( query, error );
	}
}

/**
 * Requests an organization by slug from the REST API.
 */
export const getOrganization = {
	*fulfill( slug: string ) {
		const query = { slug };
		yield fetchOrganizations( query );

		const path = addQueryArgs( '/wp-json/biz/v1/organizations', query );

		try {
			const {
				items,
				count,
				total,
				totalPages,
			} = yield apiFetch( { path } );

			yield receiveOrganizations(
				query,
				items,
				count,
				total,
				totalPages,
			);

			yield fetchOrganizationsSuccess( query );
		} catch ( error ) {
			yield fetchOrganizationsFailure( query, error );
		}
	},
	isFulfilled( state: AppState, slug: string ) {
		return ! isEmpty( state.organizations.items[ slug ] );
	},
};

/**
 * Requests news from the REST API.
 */
export const getMenus = {
	*fulfill() {
		yield fetchMenus();

		try {
			const items = yield apiFetch( { path: '/wp-json/biz/v1/menus' } );

			yield receiveMenus( items );
			yield fetchMenusSuccess();
		} catch ( error ) {
			yield fetchMenusFailure( error );
		}
	},
	isFulfilled( state: AppState ) {
		return state.menus.isFetching || ! isEmpty( state.menus.items );
	},
};

/**
 * Requests posts from the REST API.
 */
export function* getPosts( query = {} ) {
	yield fetchPosts( query );

	const path = addQueryArgs( '/wp-json/biz/v1/posts', query );

	try {
		const {
			items,
			count,
			total,
			totalPages,
		} = yield apiFetch( { path } );

		yield receivePosts(
			query,
			items,
			count,
			total,
			totalPages,
		);

		yield fetchPostsSuccess( query );
	} catch ( error ) {
		yield fetchPostsFailure( query, error );
	}
}

/**
 * Requests a post by slug from the REST API.
 */
export const getPost = {
	*fulfill( slug: string ) {
		const query = { slug };
		yield fetchPosts( query );

		const path = addQueryArgs( '/wp-json/biz/v1/posts', query );

		try {
			const {
				items,
				count,
				total,
				totalPages,
			} = yield apiFetch( { path } );

			yield receivePosts(
				query,
				items,
				count,
				total,
				totalPages,
			);

			yield fetchPostsSuccess( query );
		} catch ( error ) {
			yield fetchPostsFailure( query, error );
		}
	},
	isFulfilled( state: AppState, slug: string ) {
		return ! isEmpty( state.posts.items[ slug ] );
	},
};
