/**
 * External dependencies
 */
import React, { useEffect } from 'react';

/**
 * Internal dependencies
 */
import useMenu, { PRIMARY_MENU } from '../../hooks/useMenu';
import SearchButton from './SearchButton';
import ContentPlaceholder from '../ContentPlaceholder/ContentPlaceholder';
import { Nav, NavItem } from '../Nav';

/**
 * Check if current location is part of the trial apprenticeships routes.
 */
const isTrialApprenticeshipRoutes = ( match, location ) => {
	if ( location.pathname ) {
		const pathname = location.pathname.split( '/' )[ 1 ];
		return pathname === 'schnuppern' || pathname === 'schnupperlehre';
	}
};

/**
 * Check if current location is part of the apprenticeships routes.
 */
const isApprenticeshipRoutes = ( match, location ) => {
	if ( location.pathname ) {
		const pathname = location.pathname.split( '/' )[ 1 ];
		return pathname === 'lehrstellen' || pathname === 'lehrstelle';
	}
};

const MainNavigation = ( {
	onClick, onItemsLoaded,
} ) => {
	const [ menuItems, menuItemsIsLoading ] = useMenu( PRIMARY_MENU );

	// Execute parent callback. (Updates the list of focusable elements for focus trapping.)
	useEffect( () => {
		onItemsLoaded();
	}, [ onItemsLoaded, menuItems ] );

	return (
		<Nav className="main-navigation" label="Hauptnavigation">
			<ul>
				{
					! menuItemsIsLoading && menuItems.length > 0 && (
						<>
							{
								menuItems.map( ( item ) => {
									let isActiveCallback;
									if ( item.url === '/schnuppern' ) {
										isActiveCallback = isTrialApprenticeshipRoutes;
									} else if ( item.url === '/lehrstellen' ) {
										isActiveCallback = isApprenticeshipRoutes;
									}

									return (
										<NavItem
											key={ item.id }
											item={ item }
											onClick={ onClick }
											isActiveCallback={ isActiveCallback }
											isTopLevel={ true } />
									);
								} )
							}
						</>
					)
				}
				{
					menuItemsIsLoading && (
						<>
							<li>
								<ContentPlaceholder className="navigation__item" width={ 100 } />
							</li>
							<li>
								<ContentPlaceholder className="navigation__item" width={ 50 } />
							</li>
							<li>
								<ContentPlaceholder className="navigation__item" width={ 150 } />
							</li>
							<li>
								<ContentPlaceholder className="navigation__item" width={ 120 } />
							</li>
							<li>
								<ContentPlaceholder className="navigation__item" width={ 90 } />
							</li>
							<li>
								<ContentPlaceholder className="navigation__item" width={ 80 } />
							</li>
						</>
					)
				}
				<li className="main-navigation__search">
					<SearchButton className="navigation__item" />
				</li>
			</ul>
		</Nav>
	);
};

export default MainNavigation;
