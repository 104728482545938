/**
 * Internal dependencies
 */
import { Notice } from './types';

/**
 * Default notice status.
 *
 * @type {string}
 */
export const DEFAULT_NOTICE_STATUS: Notice['status'] = 'info';

/**
 * Notice ID for login.
 *
 * @type {string}
 */
export const LOGIN_NOTICE_ID: string = 'login-notice-id';

/**
 * Notice ID for registration.
 *
 * @type {string}
 */
export const REGISTRATION_NOTICE_ID: string = 'register-notice-id';

/**
 * Notice ID for profile updates.
 *
 * @type {string}
 */
export const PROFILE_UPDATE_NOTICE_ID: string = 'profile-update-notice-id';

/**
 * Notice ID for organization updates.
 *
 * @type {string}
 */
export const ORGANIZATION_UPDATE_NOTICE_ID: string = 'organization-update-notice-id';

/**
 * Notice ID for form requests.
 *
 * @type {string}
 */
export const FORM_UPDATE_NOTICE_ID: string = 'form-update-notice-id';

/**
 * Notice ID for event updates.
 *
 * @type {string}
 */
export const EVENT_NOTICE_ID: string = 'event-notice-id';

/**
 * Notice ID for apprenticeship slot updates.
 *
 * @type {string}
 */
export const APPRENTICESHIP_SLOT_NOTICE_ID: string = 'apprenticeship-slot-notice-id';

/**
 * Notice ID for user connection requests.
 *
 * @type {string}
 */
export const USER_CONNECTION_NOTICE_ID: string = 'user-connection-notice-id';

/**
 * Notice ID for user remembered items.
 *
 * @type {string}
 */
export const CURRENT_USER_REMEMBERED_ITEM_NOTICE_ID: string = 'user-remembered-item-notice-id';

/**
 * Default query parameter string for `/schnuppern-lehrstellen/<SLUG>?districts...`
 * This activates the query parameter to display the results for `Kanton Zürich` and its child districts by default.
 * This query activates filters in `../../src/pages/ApprenticeshipsOffers.tsx`
 *
 * @type {string}
 */
export const APPRENTICESHIPS_DISTRICTS_DEFAULT_QUERY_PARAMETER: string = 'districts%5B0%5D=2&districts%5B1%5D=3&districts%5B2%5D=4&districts%5B3%5D=5&districts%5B4%5D=6&districts%5B5%5D=7&districts%5B6%5D=11&districts%5B7%5D=12&districts%5B8%5D=13&districts%5B9%5D=501&districts%5B10%5D=575&districts%5B11%5D=614&districts%5B12%5D=44033';
