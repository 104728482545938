/**
 * External dependencies
 */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelect, useDispatch } from '@wordpress/data';

/**
 * Internal dependencies
 */
import Modal from '../Modal/Modal';
import LoginForm from '../LoginForm/LoginForm';
import { STORE_KEY } from '../../store';

import './LoginModal.scss';

const LoginModal = () => {
	const { openState } = useSelect( ( select ) => {
		const { isLoginModalOpen } = select( STORE_KEY );
		return { openState: isLoginModalOpen() };
	}, [] );

	const { closeLoginModal } = useDispatch( STORE_KEY );

	const {
		pathname,
		search,
	} = useLocation();

	const url = pathname + search;

	useEffect( () => {
		closeLoginModal();
	}, [ closeLoginModal, url ] );

	return (
		<Modal
			isOpen={ openState }
			onClose={ closeLoginModal }
			contentLabel="Bitte anmelden"
			size={ Modal.SIZE_FLUID_SMALL }
			className="login-modal"
		>
			<h1>Bitte anmelden</h1>
			<p>Um diese Funktion nutzen zu können, musst du angemeldet sein.</p>

			<LoginForm redirectTo={ pathname } />
		</Modal>
	);
};

export default LoginModal;
