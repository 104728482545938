/**
 * External dependencies
 */
import { createRegistryControl } from '@wordpress/data';

/**
 * Internal dependencies
 */
import { STORE_KEY } from '../store';
import { default as triggerApiFetch } from '../utils/apiFetch';

export function apiFetch( request ) {
	return {
		type: 'API_FETCH',
		request,
	};
}

export function dispatch( actionName, ...args ) {
	return {
		type: 'DISPATCH',
		storeKey: STORE_KEY,
		actionName,
		args,
	};
}

const controls = {
	API_FETCH( { request } ) {
		return triggerApiFetch( request );
	},
	DISPATCH: createRegistryControl(
		( registry ) => ( {
			storeKey, actionName, args,
		} ) => {
			return registry.dispatch( storeKey )[ actionName ]( ...args );
		},
	),
};

export default controls;
