import {
	LOGIN_NOTICE_ID,
	REGISTRATION_NOTICE_ID,
	PROFILE_UPDATE_NOTICE_ID,
	FORM_UPDATE_NOTICE_ID,
	USER_CONNECTION_NOTICE_ID,
	CURRENT_USER_REMEMBERED_ITEM_NOTICE_ID,
	ORGANIZATION_UPDATE_NOTICE_ID,
	APPRENTICESHIP_SLOT_NOTICE_ID,
} from '../constants';

/**
 * Builds the login fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForLoginFail( data ) {
	return [
		data.error.message && data.error.code !== 'unknown_error' ?
			data.error.message :
			'Die Anmeldung ist fehlgeschlagen.',
		{ id: LOGIN_NOTICE_ID },
	];
}

/**
 * Builds a generic fail notification message based on the error data.
 */
function getGenericNotificationMessageForFail( data: any, unknownErrorMessage: string ): string {
	let message: string;

	if ( 'unknown_error' === data.error.code ) {
		message = unknownErrorMessage;
	} else if ( 'rest_missing_callback_param' === data.error.code || 'rest_empty_callback_param' === data.error.code ) {
		message = 'Bitte alle Felder vollständig ausfüllen.';
	} else if ( 'rest_invalid_param' === data.error.code ) {
		message = 'Bitte überprüfe deine Eingaben auf Richtigkeit.';
	} else {
		message = data.error.message;
	}

	return message;
}

/**
 * Builds the registration fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForRegistrationFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Die Registrierung ist fehlgeschlagen.' ),
		{ id: REGISTRATION_NOTICE_ID },
	];
}

/**
 * Builds the profile update fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForUpdateProfileFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Die Aktualisierung ist fehlgeschlagen.' ),
		{ id: PROFILE_UPDATE_NOTICE_ID },
	];
}

/**
 * Builds the profile update fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForUpdateOrganizationFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Die Aktualisierung ist fehlgeschlagen.' ),
		{ id: ORGANIZATION_UPDATE_NOTICE_ID },
	];
}

/**
 * Builds the form fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForFormFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Das Absenden des Formulars ist fehlgeschlagen.' ),
		{ id: FORM_UPDATE_NOTICE_ID },
	];
}

/**
 * Builds the request user connection fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForRequestUserConnectionFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Die Anfrage ist fehlgeschlagen.' ),
		{ id: USER_CONNECTION_NOTICE_ID },
	];
}

/**
 * Builds the delete user connection fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForDeleteUserConnectionFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Die Löschung ist fehlgeschlagen.' ),
		{ id: USER_CONNECTION_NOTICE_ID },
	];
}

/**
 * Builds the update user connection fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForUpdateUserConnectionFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Die Aktualisierung ist fehlgeschlagen.' ),
		{ id: USER_CONNECTION_NOTICE_ID },
	];
}

/**
 * Builds the add user remembered item fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForAddUserRememberedItemFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Die Merken ist fehlgeschlagen.' ),
		{ id: CURRENT_USER_REMEMBERED_ITEM_NOTICE_ID },
	];
}

/**
 * Builds the delete user remembered item fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForDeleteUserRememberedItemFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Das Löschung ist fehlgeschlagen.' ),
		{ id: CURRENT_USER_REMEMBERED_ITEM_NOTICE_ID },
	];
}

/**
 * Builds the delete user remembered item fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForEventFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Das Erstellen der Veranstaltung ist fehlgeschlagen.' ),
		{ id: CURRENT_USER_REMEMBERED_ITEM_NOTICE_ID },
	];
}

/**
 * Builds the apprenticeship slot created item fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForCreateApprenticeshipSlotFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Das Erfassen der Schnupperstelle ist fehlgeschlagen.' ),
		{ id: APPRENTICESHIP_SLOT_NOTICE_ID },
	];
}

/**
 * Builds the apprenticeship slot deleted item fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForDeleteApprenticeshipSlotFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Das Löschen der Schnupperstelle ist fehlgeschlagen.' ),
		{ id: APPRENTICESHIP_SLOT_NOTICE_ID },
	];
}

/**
 * Builds the apprenticeship slot updated item fail notification arguments for dispatch.
 */
export function getNotificationArgumentsForUpdateApprenticeshipSlotFail( data ) {
	return [
		getGenericNotificationMessageForFail( data, 'Die Aktualisierung der Schnupperstelle ist fehlgeschlagen.' ),
		{ id: APPRENTICESHIP_SLOT_NOTICE_ID },
	];
}
