/**
 * External dependencies
 */
import React, {
	useRef, useState, useEffect, useContext,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { focusTrap, isMobile } from '../../utils';
import { HeaderContext } from './HeaderContext';
import { ReactComponent as Logo } from './logo-biz.svg';
import Button from '../Button/Button';
import MainNavigation from './MainNavigation';
import MetaNavigation from './MetaNavigation';
import ProfileNavigation from './ProfileNavigation';
import SearchButton from './SearchButton';
import SearchForm from './SearchForm';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';

import './Header.scss';

const Header = () => {
	const location = useLocation();
	const containerRef = useRef( null );
	const {
		isMenuOpen,
		isSearchFormOpen,
		openMenu,
		closeMenu,
		currentOpenSubMenus,
	} = useContext( HeaderContext );
	const [ focusableElements, setFocusableElements ] = useState( [] );

	// Handle closing the menu on Escape key press.
	useEffect( () => {
		// Return early if menu is not open.
		if ( ! isMenuOpen ) {
			return;
		}

		// Define function to close menu on Escape key press.
		const closeOnEsc = ( event ) => {
			// 27 = Escape key.
			if ( 27 === event.keyCode ) {
				closeMenu();
			}
		};

		// Listen for keypress on whole window.
		window.addEventListener( 'keydown', closeOnEsc );

		// Remove event listeners on cleanup.
		return () => {
			window.removeEventListener( 'keydown', closeOnEsc );
		};
	}, [ closeMenu, isMenuOpen ] );

	// Define function to find and store all focusable elements.
	const updateFocusableElements = ( ) => {
		const containerElement = containerRef.current;
		const focusableEls = containerElement.querySelectorAll(
			'nav > ul > li > a,' +
			'nav > ul > li > div > a,' +
			'nav > ul > li > button,' +
			'nav > ul > li > div > button,' +
			'nav > div > ul > li > a,' +
			'nav > div > ul > li > div > a,' +
			'.app-header__buttons > a,' +
			'.app-header__buttons > button',
		);

		// Set focusable elements only when they changed.
		setFocusableElements( ( prevFocusableElements ) => {
			if ( focusableEls.length !== prevFocusableElements.length ) {
				return Array.from( focusableEls );
			}
			return prevFocusableElements;
		} );
	};

	// Handle focus trapping.
	useEffect( () => {
		// Update the list of focusable elements.
		updateFocusableElements( );

		// Define function to trap the focus.
		const handleTab = ( event ) => {
			if ( focusableElements.length > 0 && ! isSearchFormOpen ) {
				focusTrap( focusableElements, event, isMenuOpen && isMobile() );
			}
		};

		// Listen for tab actions on the container element.
		const containerElement = containerRef.current;
		containerElement.addEventListener( 'keydown', handleTab );

		// Remove event listeners on cleanup.
		return () => {
			containerElement.removeEventListener( 'keydown', handleTab );
		};
	}, [ isMenuOpen, focusableElements, isSearchFormOpen ] );

	// Focus currently active menu or sub menu after opening for keyboard navigation.
	useEffect( () => {
		const containerElement = containerRef.current;
		if ( containerElement ) {
			if ( currentOpenSubMenus.length > 0 ) {
				const subMenuID = currentOpenSubMenus[ currentOpenSubMenus.length - 1 ];
				let focusElement = null;
				if ( isMobile() ) {
					focusElement = containerElement.querySelector( `#${ subMenuID }` )
						.querySelector( ':scope > li > button' );
				} else {
					focusElement = containerElement.querySelector( `#${ subMenuID }` )
						.querySelector( ':scope > li:not(.sub-navigation__item--back) > a' );
				}
				requestAnimationFrame( () => {
					focusElement.focus( { preventScroll: true } );
				} );
			} else if ( isMenuOpen ) {
				containerElement
					.querySelector(
						'nav > ul > li > a,' +
						'nav > ul > li > div > a,' +
						'nav > ul > li > button,' +
						'nav > ul > li > div > button,' +
						'nav > div > ul > li > a',
						'nav > div > ul > li > div > a',
					)
					.focus( { preventScroll: true } );
			}
		}
	}, [ isMenuOpen, currentOpenSubMenus ] );

	return (
		<header className="app-header" ref={ containerRef }>
			<div className="app-header__inner">
				<SearchForm />
				<div className="app-header__buttons">
					<Link className="app-header__home-link" to="/">
						<VisuallyHidden>
							{ '/' === location.pathname ? <h1 className="app-header__title">Berufswahl-Portal</h1> : <p className="app-header__title">Berufswahl-Portal</p> }
						</VisuallyHidden>
						<Logo className="app-header__logo" role="img" aria-hidden="true" focusable="false" />
					</Link>
					<SearchButton className="mobile-search-open" />
					<Button
						className="mobile-menu-open"
						aria-controls="navigation"
						aria-expanded={ isMenuOpen }
						onClick={ () => {
							if ( isMenuOpen ) {
								closeMenu();
							} else {
								openMenu();
							}
						} } >
						<span aria-hidden="true" className="lines">
							<span className="line first"></span>
							<span className="line second"></span>
							<span className="line third"></span>
						</span>
						Menu
					</Button>
				</div>

				<div
					id="navigation"
					className={
						classnames(
							'app-header__navigation',
							{ 'app-header__navigation--is-open': isMenuOpen },
							{ 'app-header__navigation--is-sub-open': currentOpenSubMenus.length > 0 },
						)
					}>
					<div className="app-header__navigation-content">
						<div className="app-header__navigation-slider">
							<ProfileNavigation onClick={ closeMenu } />
							<MainNavigation onClick={ closeMenu } onItemsLoaded={ updateFocusableElements } />
							<MetaNavigation onClick={ closeMenu } onItemsLoaded={ updateFocusableElements } />
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
