/**
 * Trap the tab focus inside a module.
 */
export const focusTrap = ( elements, event, condition = true ) => {
	if ( ! condition ) {
		return;
	}

	if ( ! elements ) {
		return;
	}

	// Define tab key.
	const KEYCODE_TAB = 9;

	// Check if tab key was pressed.
	const isTabPressed = event.key === 'Tab' || event.keyCode === KEYCODE_TAB;

	// Return early if another key was pressed.
	if ( ! isTabPressed ) {
		return;
	}

	// Define first and last items from focusable elements.
	const focusableEls = elements;
	const firstFocusableEl = focusableEls[ 0 ];
	const lastFocusableEl = focusableEls[ focusableEls.length - 1 ];

	if ( event.shiftKey ) {
		// Handle Shift + Tab.
		if ( firstFocusableEl.ownerDocument.activeElement === firstFocusableEl ) {
			// Jump to last focusable element if tabbed backwards from first focusable.
			lastFocusableEl.focus();
			event.preventDefault();
		}
	} else if ( lastFocusableEl.ownerDocument.activeElement === lastFocusableEl ) {
		// Handle Tab.
		// Jump to first focusable element if tabbed forwards from last.
		firstFocusableEl.focus();
		event.preventDefault();
	}
};
