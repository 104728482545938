/**
 * External dependencies
 */
import React, {
	useRef, useEffect, useState, useContext,
} from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { focusTrap, isMobile } from '../../utils';
import { HeaderContext } from '../Header/HeaderContext';
import {
	NavItem, NavLink, SubNavigationButton,
} from '.';

const SubNavigation = ( {
	item,
	onClick = null,
	isTopLevel = false,
} ) => {
	const containerRef = useRef( null );
	const {
		id, children, url, title: text,
	} = item;
	const menuId = `sub-menu--${ id }`;
	const { currentOpenSubMenus } = useContext( HeaderContext );

	// Extract childIds from children with nested sub-menus.
	const childIds = children
		.filter( ( child ) => child.children )
		.map( ( child ) => `sub-menu--${ child.id }` );

	// Check if direct child sub-menu is open.
	const isOpen = currentOpenSubMenus.includes( menuId );

	// Check if any child sub-menus are open.
	const childNavigationOpen = childIds.length > 0 && currentOpenSubMenus.some( ( childId ) => childIds.includes( childId ) );

	// Populate focusable elements and apply focus trapping.
	const [ focusableElementsMobile, setfocusableElementsMobile ] = useState( [] );
	const [ focusableElementsDesktop, setfocusableElementsDesktop ] = useState( [] );
	useEffect( () => {
		const containerElement = containerRef.current;
		const focusableElsMobile = containerElement.querySelectorAll(
			':scope > ul > li > a,' +
			':scope > ul > li > div > a,' +
			':scope > ul > li > button,' +
			':scope > ul > li > div > button',
		);
		let focusableElsDesktop = [];
		if ( isTopLevel ) {
			focusableElsDesktop = containerElement.querySelectorAll(
				':scope > button, :scope a',
			);
		}

		// Update the lists of focusable elements.
		setfocusableElementsMobile( ( prevfocusableElementsMobile ) => {
			if ( focusableElsMobile.length !== prevfocusableElementsMobile.length ) {
				return Array.from( focusableElsMobile );
			}
			return prevfocusableElementsMobile;
		} );
		setfocusableElementsDesktop( ( prevfocusableElementsDesktop ) => {
			if ( focusableElsDesktop.length !== prevfocusableElementsDesktop.length ) {
				return Array.from( focusableElsDesktop );
			}
			return prevfocusableElementsDesktop;
		} );

		// Define function to trap the focus.
		const handleTab = ( event ) => {
			if ( containerElement && focusableElementsMobile.length > 0 ) {
				focusTrap( focusableElementsMobile, event, isOpen && isMobile() );
			}
			if ( containerElement && focusableElementsDesktop.length > 0 ) {
				focusTrap( focusableElementsDesktop, event, isOpen && ! isMobile() );
			}
		};

		// Listen for tab actions on the container element.
		containerElement.addEventListener( 'keydown', handleTab );

		// Remove event listeners on cleanup.
		return () => {
			containerElement.removeEventListener( 'keydown', handleTab );
		};
	}, [ isTopLevel, isOpen, focusableElementsMobile, focusableElementsDesktop ] );

	return (
		<div
			className="sub-navigation-wrapper"
			ref={ containerRef }>
			<SubNavigationButton
				controls={ menuId }>
				{ text }
			</SubNavigationButton>
			<ul
				id={ menuId }
				className={
					classnames(
						'sub-navigation',
						{ 'sub-navigation--is-sub-open': childNavigationOpen },
					)

				}>
				<li className="sub-navigation__item sub-navigation__item--back">
					<SubNavigationButton
						controls={ menuId }
						back={ true }>
						{ text }
					</SubNavigationButton>
				</li>
				{ url && (
					<li className="sub-navigation__item sub-navigation__item--group-link">
						<NavLink
							onClick={ onClick }
							to={ url }>
							<span className="sub-navigation__link-annotation">Zu «</span>{ text }<span className="sub-navigation__link-annotation">»</span>
						</NavLink>
					</li>
				) }
				{
					children.map( ( child ) => {
						return (
							<NavItem
								className="sub-navigation__item"
								key={ child.id }
								item={ child }
								onClick={ onClick } />
						);
					} )
				}
			</ul>
		</div>
	);
};

export default SubNavigation;
